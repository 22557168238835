import { SignedIn } from "@clerk/remix"
import { Outlet } from "@remix-run/react"
import { AnimatePresence, motion } from "motion/react"

import { AddEditProvider } from "@/components/shared/add-edit/provider"
import { GlobalCommandMenu } from "@/components/global/command-menu"
import { CommandMenuProvider } from "@/components/global/command-menu/command-menu-context"
import {
	AppNavbar,
	NavbarInset,
	NavbarProvider,
} from "@/components/global/navbar"
import { TeamView } from "@/components/global/shop-watch-team-view"
import { useShopWatchGlobal } from "@/components/global/shop-watch/global-context"

export const description = "A sidebar that collapses to icons."

export default function Page() {
	const { isTeamViewOpen } = useShopWatchGlobal()

	return (
		<SignedIn>
			<CommandMenuProvider>
				<AddEditProvider>
					<div className="absolute inset-0 flex flex-col overflow-hidden lg:flex-row">
						<NavbarProvider>
							<AppNavbar />
							<div className="flex flex-1 flex-col overflow-hidden">
								<NavbarInset>
									<Outlet />
								</NavbarInset>

								{isTeamViewOpen && (
									<AnimatePresence>
										<motion.div
											initial={{ y: 10, opacity: 1 }}
											animate={{ y: 0, opacity: 1 }}
											exit={{ y: 10, opacity: 1 }}
											transition={{
												type: "spring",
												stiffness: 450,
												damping: 25,
												mass: 1,
											}}
										>
											<TeamView />
										</motion.div>
									</AnimatePresence>
								)}
							</div>
						</NavbarProvider>

						<GlobalCommandMenu />
					</div>
				</AddEditProvider>
			</CommandMenuProvider>
		</SignedIn>
	)
}
