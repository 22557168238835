import { Link } from "@remix-run/react"
import { motion } from "motion/react"

import { pagesConfig } from "@/config/pages"
import { cn } from "@/lib/utils/classnames"

import { useNavbar } from "../../context"
import { NavbarExpandedGroup } from "./group"
import { ExpandedLogo } from "./logo"
import { ExpandedQuickActions } from "./quick-actions"

export const NavbarExpandedContainer = ({
	className,
}: {
	className?: string
}) => {
	const { state, animations, isMobile } = useNavbar()

	return (
		<motion.div
			{...animations.expandedContainer}
			animate={state === "expanded" || isMobile ? "visible" : "hidden"}
			aria-hidden={state !== "expanded" && !isMobile}
			className={cn(
				"absolute inset-y-0 flex flex-col space-y-6 overflow-x-hidden py-4",
				className,
			)}
		>
			<Link to="/work/dashboard">
				<ExpandedLogo />
			</Link>
			<ExpandedQuickActions />
			<nav className="space-y-1">
				{pagesConfig.map((group) => (
					<NavbarExpandedGroup key={group.id} group={group} />
				))}
			</nav>
		</motion.div>
	)
}
