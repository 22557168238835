import { SearchIcon } from "lucide-react"

import { Button } from "@/components/ui/button"

import { useCommandMenu } from "../../command-menu/command-menu-context"

export function QuickSearchButton() {
	const { setOpen: setOpenCommandMenu } = useCommandMenu()

	return (
		<Button
			size="none"
			className="size-8"
			onClick={() => setOpenCommandMenu(true)}
		>
			<SearchIcon className="size-4" />
			<span className="sr-only">Search</span>
		</Button>
	)
}
