import { createContext, useContext, useEffect, useState } from "react"

interface CommandMenuContextType {
	open: boolean
	setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const CommandMenuContext = createContext<CommandMenuContextType | undefined>(
	undefined,
)

export function CommandMenuProvider({
	children,
}: {
	children: React.ReactNode
}) {
	const [open, setOpen] = useState(false)

	useEffect(() => {
		const down = (e: KeyboardEvent) => {
			if (e.key === "k" && (e.metaKey || e.ctrlKey)) {
				e.preventDefault()
				setOpen((open) => !open)
			}
		}
		document.addEventListener("keydown", down)
		return () => document.removeEventListener("keydown", down)
	}, [])

	return (
		<CommandMenuContext.Provider value={{ open, setOpen }}>
			{children}
		</CommandMenuContext.Provider>
	)
}

export function useCommandMenu() {
	const context = useContext(CommandMenuContext)
	if (!context) {
		throw new Error(
			"useCommandMenu must be used within a CommandMenuProvider",
		)
	}
	return context
}
