import {
	CopyIcon,
	MoreHorizontalIcon,
	PencilIcon,
	TrashIcon,
	ZapIcon,
} from "lucide-react"
import { motion } from "motion/react"

import { Button } from "@/components/ui/button"
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover"

import { useNavbar } from "../../context"
import { NotificationsButton } from "../notifications"
import { QuickAddDropdown } from "../quick-add"
import { QuickSearchButton } from "../quick-search"

export function CollapsedQuickActions() {
	const { state, animations } = useNavbar()

	return (
		<>
			<motion.div
				{...animations.collapsedSeparator}
				animate={state === "collapsed" ? "visible" : "hidden"}
				className="h-px w-full bg-foreground/[15%]"
			/>
			<Popover>
				<PopoverTrigger asChild>
					<Button size="icon" variant="ghost" className="m-0">
						<ZapIcon className="size-5 rotate-12" />
					</Button>
				</PopoverTrigger>

				<PopoverContent
					side="right"
					align="center"
					className="flex w-fit items-center gap-2 p-2"
				>
					<QuickAddDropdown />
					<QuickSearchButton />
					<NotificationsButton />
				</PopoverContent>
			</Popover>
			<motion.div
				{...animations.collapsedSeparator}
				animate={state === "collapsed" ? "visible" : "hidden"}
				className="h-px w-full bg-foreground/[15%]"
			/>
		</>
	)
}
