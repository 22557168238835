import { motion } from "motion/react"

import { useNavbar } from "../../context"
import { NotificationsButton } from "../notifications"
import { QuickAddDropdown } from "../quick-add"
import { QuickSearchButton } from "../quick-search"

export const ExpandedQuickActions = () => {
	const { animations } = useNavbar()

	return (
		<motion.div {...animations.expandedItem}>
			<div className="flex items-center justify-center gap-3">
				<QuickAddDropdown />

				<QuickSearchButton />

				<NotificationsButton />
			</div>
		</motion.div>
	)
}
