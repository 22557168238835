import {
	BellIcon,
	CalendarIcon,
	DollarSignIcon,
	MapPinIcon,
	TruckIcon,
} from "lucide-react"

import { cn } from "@/lib/utils/classnames"
import { Button } from "@/components/ui/button"
import { Link } from "@/components/ui/link"
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover"

export function NotificationsButton() {
	return (
		<Popover>
			<PopoverTrigger asChild>
				<Button size="none" className="relative size-8">
					<BellIcon className="size-4" />
					<span className="absolute -right-1 -top-1 size-2.5 rounded-full bg-stop-450" />
					<span className="sr-only">Notifications</span>
				</Button>
			</PopoverTrigger>
			<PopoverContent
				align="start"
				className="w-[360px] max-w-[100svw] p-0"
			>
				<NotificationsContent />
			</PopoverContent>
		</Popover>
	)
}

const NotificationsContent = () => {
	return (
		<div className="space-y-4">
			<div className="flex items-center justify-between px-4 pt-4">
				<h4 className="font-semibold">Notifications</h4>
				<Button size="sm" className="text-xs">
					Mark all as read
				</Button>
			</div>
			<div className="max-h-[400px] space-y-1 overflow-y-auto px-4 pb-4">
				<NotificationItem
					to="/resources/customers?details_type=customer&details_id=2"
					icon={
						<CalendarIcon className="mt-0.5 size-4 text-foreground-weak" />
					}
					title="New appointment scheduled with Hammes and Sons"
					time="2 minutes ago"
					unread
				/>
				<NotificationItem
					to="/resources/customers?details_type=customer&details_id=9"
					icon={
						<DollarSignIcon className="mt-0.5 size-4 text-foreground-weak" />
					}
					title="Invoice #1234 sent to Doyle LLC"
					time="1 hour ago"
					unread
				/>
				<NotificationItem
					to="/resources/equipment?details_type=equipment&details_id=9"
					icon={
						<TruckIcon className="mt-0.5 size-4 text-foreground-weak" />
					}
					title="Vehicle #9012 maintenance completed"
					time="2 hours ago"
					unread
				/>
				<NotificationItem
					to="/resources/equipment?details_type=equipment&details_id=20"
					icon={
						<TruckIcon className="mt-0.5 size-4 text-foreground-weak" />
					}
					title="Vehicle #0011 started oil change"
					time="4 hours ago"
				/>
				<NotificationItem
					to="/resources/equipment?details_type=equipment&details_id=8"
					icon={
						<TruckIcon className="mt-0.5 size-4 text-foreground-weak" />
					}
					title="Vehicle #8901 awaiting parts delivery"
					time="Yesterday"
				/>
				<NotificationItem
					to="/resources/equipment?details_type=equipment&details_id=14"
					icon={
						<MapPinIcon className="mt-0.5 size-4 text-foreground-weak" />
					}
					title="Truck #4455 arrived at Downtown Storage"
					time="2 days ago"
				/>
				<NotificationItem
					to="/resources/equipment?details_type=equipment&details_id=4"
					icon={
						<MapPinIcon className="mt-0.5 size-4 text-foreground-weak" />
					}
					title="Truck #4567 departed from Main Warehouse"
					time="2 days ago"
				/>
				<NotificationItem
					to="/resources/customers?details_type=customer&details_id=5"
					icon={
						<CalendarIcon className="mt-0.5 size-4 text-foreground-weak" />
					}
					title="Appointment rescheduled with Kertzmann LLC"
					time="3 days ago"
				/>
				<NotificationItem
					to="/resources/customers?details_type=customer&details_id=12"
					icon={
						<DollarSignIcon className="mt-0.5 size-4 text-foreground-weak" />
					}
					title="Invoice #1235 paid by Stiedemann and Sons"
					time="4 days ago"
				/>
				<NotificationItem
					to="/resources/equipment?details_type=equipment&details_id=10"
					icon={
						<TruckIcon className="mt-0.5 size-4 text-foreground-weak" />
					}
					title="Vehicle #0123 diagnostic completed"
					time="5 days ago"
				/>
				<NotificationItem
					to="/resources/equipment?details_type=equipment&details_id=1"
					icon={
						<MapPinIcon className="mt-0.5 size-4 text-foreground-weak" />
					}
					title="Truck #1234 arrived at North Branch"
					time="1 week ago"
				/>
				<NotificationItem
					to="/resources/equipment?details_type=equipment&details_id=6"
					icon={
						<MapPinIcon className="mt-0.5 size-4 text-foreground-weak" />
					}
					title="Truck #6789 departed from South Depot"
					time="1 week ago"
				/>
			</div>
		</div>
	)
}

interface NotificationItemProps {
	to: string
	icon: React.ReactNode
	title: string
	time: string
	unread?: boolean
}

const NotificationItem = ({
	to,
	icon,
	title,
	time,
	unread,
}: NotificationItemProps) => {
	return (
		<Link
			to={to}
			className={cn(
				"flex items-center gap-3 rounded-lg px-3 py-2 transition-colors hover:bg-background-weaker",
				unread && "bg-welcome-500/[10%] hover:bg-welcome-500/[14%]",
			)}
		>
			<div className="flex w-2 items-center justify-center">
				{unread && (
					<span className="size-1.5 rounded-full bg-fiveo-500" />
				)}
			</div>
			{icon}
			<div className="flex-1">
				<p className="truncate text-sm font-medium">{title}</p>
				<p className="truncate text-xs text-foreground-weak">{time}</p>
			</div>
		</Link>
	)
}
