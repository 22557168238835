import { Link } from "@remix-run/react"
import { motion } from "motion/react"

import { pagesConfig } from "@/config/pages"
import { cn } from "@/lib/utils/classnames"

import { useNavbar } from "../../context"
import { NavbarCollapsedGroup } from "./group"
import { CollapsedLogo } from "./logo"
import { CollapsedQuickActions } from "./quick-actions"

export const NavbarCollapsedContainer = ({
	className,
}: {
	className?: string
}) => {
	const { state, animations } = useNavbar()

	return (
		<motion.div
			{...animations.collapsedContainer}
			animate={state === "collapsed" ? "visible" : "hidden"}
			aria-hidden={state !== "collapsed"}
			className={cn(
				"absolute inset-y-0 flex flex-col items-center space-y-4 overflow-x-auto",
				className,
			)}
		>
			<Link to="/work/dashboard">
				<CollapsedLogo />
			</Link>
			<CollapsedQuickActions />
			<nav className="space-y-1">
				{pagesConfig.map((group, i) => (
					<NavbarCollapsedGroup
						key={group.id}
						group={group}
						hasSeparator={i > 0}
					/>
				))}
			</nav>
		</motion.div>
	)
}
