import { PlusIcon } from "lucide-react"

import { addEditConfig } from "@/config/add-edit"
import type { AddEditConfigKeys } from "@/config/add-edit"
import { featureConfig } from "@/config/features"
import { Button } from "@/components/ui/button"
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { useAddEdit } from "@/components/shared/add-edit/provider"

export function QuickAddDropdown() {
	const addEdits = useAddEdit()
	const availableAddEdits = Object.keys(addEditConfig) as AddEditConfigKeys[]
	return (
		<DropdownMenu>
			<DropdownMenuTrigger asChild>
				<Button size="none" className="size-8">
					<PlusIcon className="size-4" />
					<span className="sr-only">Create new</span>
				</Button>
			</DropdownMenuTrigger>
			<DropdownMenuContent align="start" className="w-[200px]">
				{availableAddEdits.map((addEdit) => {
					const feature = featureConfig[addEdit]
					return (
						<DropdownMenuItem
							key={addEdit}
							onClick={() => addEdits.openDialog(addEdit)}
							className={"justify-start px-4 py-2 text-base"}
						>
							New {feature.labels.singular}
						</DropdownMenuItem>
					)
				})}
			</DropdownMenuContent>
		</DropdownMenu>
	)
}
